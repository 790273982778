.resetPasswordContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .resetCard {
        max-width: 350px;
        width: -webkit-fill-available;

        .headerText {
            text-align: center;
            background-color: #eed7ff;
            padding: 0.8rem 0;
            margin-bottom: 0.5rem;
            border-radius: 0.3rem;
            font-weight: 700;
        }

        .linksContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1.5rem;
        }
    }
}
