.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .verifyCard {
        max-width: 500px;

        .headerText {
            text-align: center;
            background-color: #eed7ff;
            padding: 0.8rem 0;
            margin-bottom: 0.5rem;
            border-radius: 0.3rem;
            font-weight: 700;
        }

        .backtoLoginLink {
            display: flex;
            align-items: center;
            margin-top: 1.5rem;
            justify-content: flex-end;
        }
    }
}
